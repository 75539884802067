
export default {
  name: "FarmingHeader",
  props: {
    items: Array,
  },
  data() {
    return {
      title: "自然农法简介",
      des: `发源于中国道家思想，并与现代量子思维高度吻合。在农业上实践"无"的哲学，"自然农法"提倡：不耕地、不施肥、不打药、不除草，
      运用"互生、共存、共荣”的理念，最大限度尊重自然规律，减少人为干预，保持农作生态有序循环。帮助人们获得更有力量的食物，改善土地
      荒漠，保护生态环境。`,
    };
  },
};
