import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-502d954e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container" }
const _hoisted_2 = { class: "page_title" }
const _hoisted_3 = { class: "page_content_customize_width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString($data.des), 1)
  ]))
}