
export default {
  name: "RelatedPeople",
  data() {
    return {
      title: "相关人物",
      items: [
        {
          name: "福冈正信",
          des: "他说：我做农民公民，就是为了确认和证实人类并非全知。我不反对科学，只是反对傲慢的科学。",
          img: "r_figure_01.png",
        },
        {
          name: "木村秋则",
          des: `木村秋则放弃那些让他妻子严重过敏的农药，以有机的方式种植苹果。可是这一实验就是十年，直到第十一年，
          木村阿公的苹果园才开出了花朵。有人把木村阿公的故事写成了书，有人给他拍成了电影，木村却依然是那个亲自种地、
          到处教授堆肥技术的老人。`,
          img: "r_figure_02.png",
        },
        {
          name: "藤田和芳",
          des: `1975年，藤田和芳创办守护大地协会，由消费者和生产者联合发起，成为日本社会推行有机农业最早的机构之一。
          时至今天，现在的守护大地协会已发展成为拥有2500个生产会员、9.1万个消费会员、年营业额达150多亿日元的庞大组织。`,
          img: "r_figure_03.png",
        },
        {
          name: "池田秀夫",
          des: `出生于1935年的日本福冈县，年过花甲之后开始来到异国他乡，从此长年跋涉在中国的乡间田野，穿行于泥土之间。
          从2002年开始，池田秀夫四处讲学，教导健康的土壤对生态有机农业的重要性，培训中国新农人如何堆肥，减少直至放弃化肥的使用。`,
          img: "r_figure_04.png",
        },
        {
          name: "川崎广人",
          des: `在河南原阳县的小刘固农村，数年如一日地坚持着改良农场土壤，推广循环农业，培训过数干名的新农人。`,
          img: "r_figure_05.png",
        },
        {
          name: "金子美登夫妇",
          des: `身为有机领域的先驱实践者，霜里有机农场夫妇在日本有机农业领域耕耘了半个世纪，获得第六届“一个地球奖”获得终身成就奖。`,
          img: "r_figure_06.png",
        },
      ],
    };
  },
};
