
import { Options, Vue } from "vue-class-component";
import FarmingHeader from "./FarmingHeader.vue";
import Recommend from "./Recommend.vue";
import RelatedPeople from "./RelatedPeople.vue";

@Options({
  components: {
    FarmingHeader,
    Recommend,
    RelatedPeople,
  },
  data() {
    return {};
  },
})
export default class Farming extends Vue {}
