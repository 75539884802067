
const r_movie = require("@/assets/framing/r_movie.png");
const r_book = require("@/assets/framing/r_book.png");

export default {
  name: "Recommend",
  data() {
    return {
      items: [
        {
          theme: "电影推荐",
          img: r_movie,
          title: "奇迹的苹果",
          des: [
            `《奇迹的苹果》是由东宝国际发行的剧情片，该片由中村义洋执导，阿部隆史、菅野美穗、池内博之等主演。`,
            `该片根据真实事迹改编，讲述了主人公木村秋则以妻子健康状况不佳为契机，
              坚持对苹果进行无农药栽培，辛勤劳作11年终于培育出纯天然苹果的感人故事。该片于2013年6月8日在日本上映。`,
          ],
        },
        {
          theme: "书籍推荐",
          img: r_book,
          title: "一颗稻草的使命",
          des: [
            `1975年，福冈正信出版的《一根稻草的革命》，获得了许多世界级大奖，成为生态农人们的思想导航。`,
            `一场孰其来的疾病让年触福冈正信重新思考人生，为何小鸟能在空中自由飞翔，而人类却要每日为衣食奔波？为何原本自然生长的稻田，
              在会加了农药和机械的助力后，地力反而衰竭了？是否人类也在走向迷途？福冈正信用数十年的时间证明"不耕作、不施肥、不除草、
              不用农药"才是理想的稻作，他也因此寻找到了尊重生命，与地球友善相处的自然农法。`,
          ],
        },
      ],
    };
  },
};
